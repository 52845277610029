<template>
  <p class="has-text-centered">Site under construction</p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomePage',
  components: {},
});
</script>
