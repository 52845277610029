<template>
  <header class="has-text-centered">
    <h2>Coming soon.</h2>
  </header>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss">
@import url('https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css');
@import url('https://fonts.googleapis.com/css2?family=Monoton&family=Poppins:ital,wght@0,100;0,300;0,500;0,700;0,800;1,300;1,400;1,600;1,800&family=Roboto+Mono:ital,wght@0,400;0,600;1,400;1,600&display=swap');
body {
  font-family: 'Poppins', sans-serif;
  font-family: 'Monoton', cursive;
  font-family: 'Roboto Mono', monospace;
  background-color: rgb(51, 51, 51);
  height: 100vh;
  color: #ddd;
  overflow: hidden;
}

h2 {
  color: #ddd;
}
</style>
